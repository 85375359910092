import { collection, collectionGroup, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { auth, db } from '@/config/firebase'
import { onMounted, ref } from 'vue'
import { getUserData } from '@/services/user.service'
import { useLoadingStore } from '@/stores/loading'
import { getIdToken, type User } from 'firebase/auth'
import api from '@/config/axios'
import type { AxiosRequestConfig } from 'axios'
import { useSnackbarStore } from '@/stores/snakebar'
import { useRouter } from 'vue-router'
import collect from 'collect.js'

export type KnowledgeAreaPerformance = {
  id: string
  name: string
  total: number
  hits: number
}

export const usePerformanceView = () => {
  const knowledgeAreas = ref<KnowledgeAreaPerformance[]>([])
  const userData = ref()
  const router = useRouter()
  let questionWithCorrect: any[] = []

  onMounted(async () => {
    userData.value = await getUserData()
    try {
      useLoadingStore().startLoading()
      await loadUserApplications()
    } catch {
      router.go(-1)
      useSnackbarStore().showSnackbar(['Erro ao carregar dados'], 'error')
    } finally {
      useLoadingStore().stopLoading()
    }
  })

  const loadUserApplications = async () => {
    const ref = collection(db, 'users', userData.value.id, 'applications')
    const snapshot = await getDocs(ref)

    const applications = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        name: doc.get('name'),
        organization_id: doc.get('organization_id'),
        ref: doc.ref
      }
    })

    const formsRefs = applications.map((app) => collection(app.ref, 'forms'))

    const forms = await Promise.all(formsRefs.map((ref) => getDocs(ref)))

    const subFormsRef = forms.map((form) =>
      form.docs.map((doc) => collection(doc.ref, 'sub_forms'))
    )

    const subForms = await Promise.all(subFormsRef.map((ref) => Promise.all(ref.map(getDocs))))

    const questionsRef = subForms.map((subForm) =>
      subForm.map((sf) => sf.docs.map((doc) => collection(doc.ref, 'questions')))
    )

    const questions = await Promise.all(
      questionsRef.map((ref) => Promise.all(ref.map((ref) => Promise.all(ref.map(getDocs)))))
    )

    const userQuestions = questions.map((q) =>
      q.map((q) =>
        q.map((q) =>
          q.docs.map((docq) => {
            return {
              id: docq.id,
              answers: docq.get('answers'),
              knowledge_area_id: docq.get('knowledge_area_id'),
              alternatives: docq.get('alternatives'),
              competency_matrices: docq.get('competency_matrices'),
              main_text: docq.get('main_text'),
              feedback: docq.get('feedback'),
              comment: docq.get('comment'),
              author_id: docq.get('author_id'),
              grade: docq.get('grade'),
              partial_grade: docq.get('partial_grade'),
              ref: doc(docq.get('sub_form_ref'), 'questions', docq.id),
              isCorrect: false
            }
          })
        )
      )
    )

    const flatQuestions = userQuestions
      .flat(Infinity)
      .filter((q: any) => q?.knowledge_area_id?.length)

    let kaIds = flatQuestions.map((q: any) => q.knowledge_area_id as string)
    kaIds = [...new Set(kaIds)]

    questionWithCorrect = await Promise.all(
      flatQuestions.map(async (q: any) => {
        const formQuestion = await getDoc(q.ref)
        const correctAnswerId = formQuestion.get('alternatives')?.find((a: any) => a?.is_correct)
        const isCorrect = correctAnswerId?.id === q.answers?.[0]
        return {
          ...q,
          isCorrect
        }
      })
    )

    const kaRef = collectionGroup(db, 'knowledge_areas')
    const q = query(kaRef, where('id', 'in', kaIds))
    const response = await getDocs(q)
    knowledgeAreas.value = response.docs.map((doc) => {
      const total = questionWithCorrect.filter((q: any) => q.knowledge_area_id === doc.id).length
      const hits = questionWithCorrect.filter(
        (q: any) => q.knowledge_area_id === doc.id && q.isCorrect
      ).length
      return {
        id: doc.id,
        name: doc.get('name'),
        hits: hits,
        total: total
      } as KnowledgeAreaPerformance
    })
  }

  const generateReinforceStudyFromQuestions = async (ka: KnowledgeAreaPerformance) => {
    const payload = {
      organization_id: localStorage.getItem('activeOrganization') as string,
      user_id: userData.value.id,
      name: ka.name,
      questions_stringified: mountQuestionStringified(ka.id)
    } as const
    try {
      useLoadingStore().startLoading()
      const token = await getIdToken(auth?.currentUser as User, true)
      await api.post('/generateReinforceStudyFromQuestions', payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      } as AxiosRequestConfig)
      await router.push({ name: 'dashboard' })
      useSnackbarStore().showSnackbar(['Reforço de estudo gerado com sucesso.'], 'success')
    } catch (e) {
      await generateReinforceStudyFromQuestions(ka)
    } finally {
      useLoadingStore().stopLoading()
    }
  }

  const mountQuestionStringified = (id: string) => {
    const questionsData = questionWithCorrect.filter((q: any) => q.knowledge_area_id === id)
    const questions = collect(questionsData).shuffle().take(5).all()
    return questions
      ?.map(
        (q: any) => `
            Identificador da questão: ${q?.id}
            Enunciado: ${q?.main_text?.replace(/<\/?[^>]+(>|$)/g, '')}
            Alternativas: ${q?.alternatives
              ?.map((a: any) => a?.wording?.replace(/<\/?[^>]+(>|$)/g, ''))
              ?.join(', ')}
            Competências: ${q?.competency_matrices.join(', ')}
            Área de conhecimento: ${q?.knowledge_area_id}
            Identificador do autor: ${q?.author_id}
            Resposta do estudante: ${q?.answers?.join(', ')?.replace(/<\/?[^>]+(>|$)/g, '')}
            Feedback: ${(q?.feedback ?? q?.comment)?.replace(/<\/?[^>]+(>|$)/g, '')}
          `
      )
      ?.join('\n')
  }

  return {
    knowledgeAreas,
    generateReinforceStudyFromQuestions
  }
}
