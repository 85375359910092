<template>
  <div
    :class="{
      'mx-10': !mobile,
      'mx-2': mobile
    }"
  >
    <div id="mainToolbarParent">
      <div
        id="mainToolbar"
        class="flex justify-between bg-[#fff] h-[5rem] rounded-b-xl px-5 py-5 z-40 mb-5"
      >
        <div class="flex justify-between items-center">
          <EnemSimuladoIcon />
        </div>
        <div class="flex items-center justify-center">
          <v-btn class="!border-2 text-none" color="blue" variant="outlined" @click="btnAction">
            <span :style="fontSm">Voltar</span>
          </v-btn>
        </div>
      </div>
    </div>

    <v-row no-gutters>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-sheet class="py-[20px] px-[24px] md:mr-[20px] rounded-xl shadow-sheet">
              <div class="flex justify-between space-x-2">
                <div>
                  <span :style="fontXl" class="font-medium text-sm line-clamp-2">
                    {{ data.application?.name }}
                  </span>
                </div>
                <div class="bg-[#1C86E3] text-white py-2 px-3 rounded-lg text-right text-xs">
                  <div>Nota</div>
                  <div class="font-medium text-base">
                    {{ data.userApplication?.correction?.final_grade
                    }}<span class="text-xs opacity-70">/{{ maxGrade }}</span>
                  </div>
                </div>
              </div>
            </v-sheet>
          </v-col>
          <v-col>
            <v-expansion-panels variant="default">
              <v-expansion-panel :readonly="true" class="rounded-xl shadow-sheet" elevation="0">
                <template #title>
                  <div class="flex flex-row gap-4">
                    <svg
                      fill="none"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect fill="#FCFCFC" height="24" width="24" />
                      <path
                        d="M4 23C3.73478 23 3.48043 22.8946 3.29289 22.7071C3.10536 22.5196 3 22.2652 3 22V19C3 18.7348 3.10536 18.4804 3.29289 18.2929C3.48043 18.1054 3.73478 18 4 18C4.26522 18 4.51957 18.1054 4.70711 18.2929C4.89464 18.4804 5 18.7348 5 19V22C5 22.2652 4.89464 22.5196 4.70711 22.7071C4.51957 22.8946 4.26522 23 4 23ZM13 22V15C13 14.7348 12.8946 14.4804 12.7071 14.2929C12.5196 14.1054 12.2652 14 12 14C11.7348 14 11.4804 14.1054 11.2929 14.2929C11.1054 14.4804 11 14.7348 11 15V22C11 22.2652 11.1054 22.5196 11.2929 22.7071C11.4804 22.8946 11.7348 23 12 23C12.2652 23 12.5196 22.8946 12.7071 22.7071C12.8946 22.5196 13 22.2652 13 22ZM20 11C19.7348 11 19.4804 11.1054 19.2929 11.2929C19.1054 11.4804 19 11.7348 19 12V22C19 22.2652 19.1054 22.5196 19.2929 22.7071C19.4804 22.8946 19.7348 23 20 23C20.2652 23 20.5196 22.8946 20.7071 22.7071C20.8946 22.5196 21 22.2652 21 22V12C21 11.7348 20.8946 11.4804 20.7071 11.2929C20.5196 11.1054 20.2652 11 20 11ZM20.382 1.077C20.261 1.0263 20.1312 1.00013 20 1H16C15.7348 1 15.4804 1.10536 15.2929 1.29289C15.1054 1.48043 15 1.73478 15 2C15 2.26522 15.1054 2.51957 15.2929 2.70711C15.4804 2.89464 15.7348 3 16 3H17.586L12 8.586L8.707 5.293C8.51947 5.10553 8.26516 5.00021 8 5.00021C7.73484 5.00021 7.48053 5.10553 7.293 5.293L3.293 9.293C3.11084 9.4816 3.01005 9.7342 3.01233 9.9964C3.0146 10.2586 3.11977 10.5094 3.30518 10.6948C3.49059 10.8802 3.7414 10.9854 4.0036 10.9877C4.2658 10.99 4.5184 10.8892 4.707 10.707L8 7.414L11.293 10.707C11.4805 10.8945 11.7348 10.9998 12 10.9998C12.2652 10.9998 12.5195 10.8945 12.707 10.707L19 4.414V6C19 6.26522 19.1054 6.51957 19.2929 6.70711C19.4804 6.89464 19.7348 7 20 7C20.2652 7 20.5196 6.89464 20.7071 6.70711C20.8946 6.51957 21 6.26522 21 6V2C20.9998 1.80229 20.9409 1.60909 20.8309 1.4448C20.7209 1.28052 20.5647 1.15252 20.382 1.077Z"
                        fill="#1C86E3"
                      />
                    </svg>
                    <v-label class="font-medium text-sm">Meu desempenho</v-label>
                  </div>
                </template>
                <template #text>
                  <div v-if="feedbackPerformance.isSuccessful.value" class="flex flex-col">
                    <SubjectProgressBar
                      v-for="item of feedbackPerformance.knowledgeAreas.value"
                      :key="item.id"
                      :correct="item.hits"
                      :subject="item.name"
                      :total="item.total"
                      class="my-[24px]"
                    />
                    <v-btn
                      class="mt-[24px] self-center"
                      elevation="0"
                      @click="
                        feedbackPerformance.generateReinforceStudy(
                          data.userApplication.user_id,
                          data.userApplication.application_id
                        )
                      "
                      >Gerar reforço</v-btn
                    >
                  </div>
                  <div v-else class="text-center pa-5">
                    <v-label class="font-medium text-sm">Desempenho não disponível</v-label>
                  </div>
                </template>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <template v-for="subFormQuestions of data.questions" :key="subFormQuestions.subFormOrder">
            <v-col
              v-for="(question, index) of (collect(subFormQuestions.questions) as any)
        .sortBy('order')
        .all()"
              :id="question.id"
              :key="question.id"
              cols="12"
            >
              <v-sheet class="py-[20px] px-[24px] md:mr-[20px] rounded-xl shadow-sheet">
                <v-main>
                  <div class="flex justify-between">
                    <div class="flex items-center">
                      <div class="mr-[12px]">
                        <v-chip
                          :style="fontSm"
                          class="!font-medium"
                          color="blue"
                          rounded
                          variant="elevated"
                          >{{ index + 1 }}
                        </v-chip>
                      </div>
                      <div class="mr-[12px]">
                        <v-chip
                          :style="fontSm"
                          class="!font-medium"
                          color="blue"
                          rounded
                          variant="elevated"
                          >{{ getQuestionType(question.type) }}
                        </v-chip>
                      </div>
                      <div>
                        <v-tooltip :text="getQuestionDescription(question.type)" open-on-click>
                          <template v-slot:activator="{ props }">
                            <v-chip class="!font-medium" color="blue" rounded v-bind="props">
                              <span :style="fontSm">?</span>
                            </v-chip>
                          </template>
                        </v-tooltip>
                      </div>
                    </div>
                    <div class="bg-[#1C86E3] text-white py-2 px-3 rounded-lg text-sm">
                      <div class="text-right text-xs">Nota</div>
                      <div class="text-right font-medium text-base">
                        {{ getQuestionFinalGrade(question)
                        }}<span class="tespanxt-xs opacity-70">/{{ question.grade }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    :style="fontBase"
                    class="mt-[12px] mb-[20px]"
                    v-html="question.main_text"
                  ></div>
                  <template v-if="question.type === QuestionType.OBJECTIVE">
                    <ObjectiveAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.MATRIX">
                    <MatrixAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.TRUE_FALSE">
                    <TrueFalseAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.OPEN_ENDED">
                    <OpenEndedAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.MULTIPLE_CHOICE">
                    <MultipleChoiceAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.SORTING">
                    <SortingAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.FILL_IN_THE_BLANK">
                    <FillInTheBlankAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.CONNECT_THE_DOTS">
                    <ConnectTheDotsAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.ESSAY">
                    <EssayAlternatives :question="question" />
                  </template>

                  <div v-if="question.feedback" class="relative mt-8">
                    <div
                      :class="{
                        'absolute left-6 -top-[12px] px-1 bg-white': !mobile,
                        'relative px-1 bg-white': mobile
                      }"
                    >
                      <span class="text-[#808080] text-xs font-normal not-italic">Correção</span>
                    </div>
                    <div
                      class="flex flex-col px-[10px] py-[10px] border-[#E8F3FC] items-start self-stretch rounded-[20px] border-2"
                      v-html="question.feedback"
                    ></div>
                  </div>
                </v-main>
              </v-sheet>
            </v-col>
          </template>
          <div class="pt-[20px] pb-[60px] w-full flex justify-end items-center mr-[28px]">
            <v-btn
              class="!border-2 text-none bg-white"
              color="blue"
              variant="outlined"
              @click="btnAction"
            >
              <span :style="fontSm">Sair</span>
            </v-btn>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts" setup>
import ConnectTheDotsAlternatives from '@/components/feedback/alternatives/ConnectTheDotsAlternatives.vue'
import FillInTheBlankAlternatives from '@/components/feedback/alternatives/FillInTheBlankAlternatives.vue'
import MatrixAlternatives from '@/components/feedback/alternatives/MatrixAlternatives.vue'
import MultipleChoiceAlternatives from '@/components/feedback/alternatives/MultipleChoiceAlternatives.vue'
import ObjectiveAlternatives from '@/components/feedback/alternatives/ObjectiveAlternatives.vue'
import OpenEndedAlternatives from '@/components/feedback/alternatives/OpenEndedAlternatives.vue'
import SortingAlternatives from '@/components/feedback/alternatives/SortingAlternatives.vue'
import TrueFalseAlternatives from '@/components/feedback/alternatives/TrueFalseAlternatives.vue'
import { QuestionType } from '@/enums/question-type.enum'
import {
  calculateFormQuestionsGrade,
  getApplicationQuestions,
  getFormQuestionsFromOrganization
} from '@/services/application.service'
import { useFontZoomStore } from '@/stores/font'
import collect from 'collect.js'
import { computed, onMounted, provide, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import EssayAlternatives from '@/components/feedback/alternatives/EssayAlternatives.vue'
import { getQuestionDescription, getQuestionType } from '@/services/question.service'
import EnemSimuladoIcon from '@/components/icons/EnemSimuladoIcon.vue'
import SubjectProgressBar from '@/components/feedback/SubjectProgressBar.vue'
import { useFeedBackPerformance } from '@/composables/useFeedBackPerformance'
import { useLoadingStore } from '@/stores/loading'

const { mobile } = useDisplay()
const route = useRoute()
const router = useRouter()
const feedbackPerformance = useFeedBackPerformance()
const loadingStore = useLoadingStore()

const data = ref({} as any)
const formQuestions = ref([] as any)
const maxGrade = ref(0)

const fontSm = computed(() => useFontZoomStore().getFontSm)
const fontBase = computed(() => useFontZoomStore().getFontBase)
const fontXl = computed(() => useFontZoomStore().getFontXl)

onMounted(async () => {
  try {
    loadingStore.startLoading()
    data.value = await getApplicationQuestions(route.params.applicationId as string, true)
    formQuestions.value = await getFormQuestionsFromOrganization(data.value?.application?.form_id)

    if (!data.value) {
      await router.push({ name: 'dashboard' })
    }
    feedbackPerformance.execute(formQuestions?.value, data.value?.questions)

    maxGrade.value = await calculateFormQuestionsGrade(data.value?.application?.form_ref)

    document.querySelectorAll('iframe').forEach((iframe) => {
      iframe.setAttribute('width', (window!.innerWidth * 0.45).toString())
    })
  } finally {
    loadingStore.stopLoading()
  }
})

const btnAction = () => {
  router.push({ name: 'dashboard' })
}

provide('formQuestions', formQuestions)

const getQuestionFinalGrade = (question: any) =>
  question?.final_grade ?? question?.partial_grade ?? 0

const handleScroll = (
  elementId: string,
  parentElementId: string,
  offset: number,
  additionalClass: string = ''
) => {
  const element = document.getElementById(elementId) as HTMLElement
  const parentElement = document.getElementById(parentElementId) as HTMLElement

  if (!element) return

  if (window.scrollY > offset) {
    element.classList.add('position-fixed')
    if (additionalClass) element.classList.add(additionalClass)
    element.style.width = `${parentElement.offsetWidth}px`
    return
  }

  element.classList.remove('position-fixed')
  if (additionalClass) element.classList.remove(additionalClass)
  element.style.width = ''
}

window.addEventListener('scroll', () => handleScroll('mainToolbar', 'mainToolbarParent', 60))
</script>
