<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  subject: {
    type: String,
    required: true
  },
  correct: {
    type: Number,
    required: true
  },
  total: {
    type: Number,
    required: true
  }
})

const percentage = computed(() => {
  return (props.correct / props.total) * 100
})

</script>

<template>
  <div class="flex flex-col gap-3">
    <div class="flex flex-row justify-between">
      <span class="text-[14px] font-rubik font-medium leading-[20px] tracking-[0.1px] text-[#333333]">{{subject}}</span>
      <span class="text-[11px] font-rubik font-normal leading-[16px] text-[#808080]">Questões corretas: {{ correct }}/{{total}}</span>
    </div>
    <!-- BARRA DE PROGRESS-->
    <div class="text-end">
      <v-progress-linear
        :model-value="percentage"
        rounded
        color="#115188"
        height="20" />
      <span class="text-[11px] font-rubik font-normal leading-[16px] text-right text-[#808080]">{{ Math.ceil(percentage) }}%</span>
    </div>
  </div>
</template>
