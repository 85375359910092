import { ref } from 'vue'
import { collectionGroup, getDocs, query, where } from 'firebase/firestore'
import { auth, db } from '@/config/firebase'
import collect from 'collect.js'
import api from '@/config/axios'
import type { AxiosRequestConfig } from 'axios'
import { getIdToken, type User } from 'firebase/auth'
import { useLoadingStore } from '@/stores/loading'
import { useRouter } from 'vue-router'
import { useSnackbarStore } from '@/stores/snakebar'

export type KnowLedgeArePerformance = {
  id: string,
  name: string,
  hits: number,
  total: number
}

type QuestionCorrect = {
  kaId: string,
  isCorrect: boolean
}

export const useFeedBackPerformance = () => {

  const questions = ref<any[]>([])
  const knowledgeAreas = ref<KnowLedgeArePerformance[]>([])
  const correctQuestions = ref<QuestionCorrect[]>([])
  const isSuccessful = ref(false)
  const router = useRouter()

  const execute = (formQuestions: any, userQuestions: any) => {
    questions.value = formQuestions
    correctQuestions.value = userQuestions?.[0].questions?.map((q: any) => {
      const formQuestion = formQuestions?.find((fq: any) => fq.id === q.id)
      if (!formQuestion) return { kaId: '', isCorrect: false } as QuestionCorrect

      const correctAnswerId = formQuestion?.alternatives?.find((a: any) => a.is_correct).id

      return {
        kaId: formQuestion?.knowledge_area_id,
        isCorrect: correctAnswerId === q?.answers?.[0]
      }
    })

    const ids = [...new Set(questions.value.map((q: any) => q.knowledge_area_id).filter((id: any) => id))]

    if (!ids.length) return

    loadKnowLedgeAreas(ids)
      .then(() => {
        isSuccessful.value = true
      })

  }

  const loadKnowLedgeAreas = async (ids: string[]) => {
    const ref = collectionGroup(db, 'knowledge_areas')
    const q = query(ref, where('id', 'in', ids))
    const response = await getDocs(q)
    knowledgeAreas.value = response.docs.map((doc: any) => {
      const total = questions.value.filter((q: any) => q.knowledge_area_id === doc.id).length
      const hits = correctQuestions.value.filter((q: any) => q.kaId === doc.id && q.isCorrect).length
      return {
        id: doc.id,
        name: doc.get('name'),
        total: total,
        hits: hits
      } as KnowLedgeArePerformance
    })

    knowledgeAreas.value = collect(knowledgeAreas.value).unique('id').all()
  }


  const generateReinforceStudy = async (userId: string, applicationId: string) => {
    const payload = {
      organization_id: localStorage.getItem('activeOrganization') as string,
      user_id: userId,
      application_id: applicationId,
    } as const
    try {
      useLoadingStore().startLoading()
      const token = await getIdToken(auth?.currentUser as User, true)
      await api.post('/generateReinforceStudy', payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      } as AxiosRequestConfig)
      await router.push({ name: 'dashboard' })
      useSnackbarStore().showSnackbar(['Reforço de estudo gerado com sucesso'], 'success')
    } catch (e) {
      await generateReinforceStudy(userId, applicationId)
    } finally {
      useLoadingStore().stopLoading()
    }
  }

  return {
    execute,
    knowledgeAreas,
    isSuccessful,
    generateReinforceStudy
  }

}
