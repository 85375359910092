<template>
  <v-row>
    <v-col class="space-y-5 w-full">
      <div class="rounded-xl bg-[#fff] p-5">
        <div class="flex items-center space-x-3 mb-5">
          <ExamsOpenIcon />
          <span class="text-B&W-20 font-medium !text-[1rem] leading-6 tracking-[0.15px]">
            Simulados em aberto
          </span>
          <v-chip color="blue" rounded size="small" variant="flat"
            >{{ applicationsNotStarted?.length }}
          </v-chip>
        </div>
        <template v-if="isLoadingOpenApplications">
          <ApplicationSkeletonLoader />
        </template>
        <template v-else-if="applicationsNotStarted?.length">
          <ApplicationsList
            :applications="applicationsNotStarted"
            :is_not_started="true"
            @resume="resumeApplication($event)"
            @start="startApplication($event)"
          />
        </template>
        <template v-else>
          <div class="flex justify-center items-center pt-[24px] flex-column">
            <div class="mb-[24px] font-bold">Você não tem simulados em aberto!</div>
            <img :src="svgPath.student_no_exams" alt="Estudante sem Simulados encontradas" />
          </div>
        </template>
      </div>
      <div
        v-if="paginatedApplicationsFinished?.length"
        class="rounded-xl bg-[#fff] p-5 cursor-pointer hover:bg-gray-300"
        @click="router.push({ name: 'performance' })"
      >
        <div class="flex justify-between">
          <div class="flex flex-row gap-2">
            <svg
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 23C3.73478 23 3.48043 22.8946 3.29289 22.7071C3.10536 22.5196 3 22.2652 3 22V19C3 18.7348 3.10536 18.4804 3.29289 18.2929C3.48043 18.1054 3.73478 18 4 18C4.26522 18 4.51957 18.1054 4.70711 18.2929C4.89464 18.4804 5 18.7348 5 19V22C5 22.2652 4.89464 22.5196 4.70711 22.7071C4.51957 22.8946 4.26522 23 4 23ZM13 22V15C13 14.7348 12.8946 14.4804 12.7071 14.2929C12.5196 14.1054 12.2652 14 12 14C11.7348 14 11.4804 14.1054 11.2929 14.2929C11.1054 14.4804 11 14.7348 11 15V22C11 22.2652 11.1054 22.5196 11.2929 22.7071C11.4804 22.8946 11.7348 23 12 23C12.2652 23 12.5196 22.8946 12.7071 22.7071C12.8946 22.5196 13 22.2652 13 22ZM20 11C19.7348 11 19.4804 11.1054 19.2929 11.2929C19.1054 11.4804 19 11.7348 19 12V22C19 22.2652 19.1054 22.5196 19.2929 22.7071C19.4804 22.8946 19.7348 23 20 23C20.2652 23 20.5196 22.8946 20.7071 22.7071C20.8946 22.5196 21 22.2652 21 22V12C21 11.7348 20.8946 11.4804 20.7071 11.2929C20.5196 11.1054 20.2652 11 20 11ZM20.382 1.077C20.261 1.0263 20.1312 1.00013 20 1H16C15.7348 1 15.4804 1.10536 15.2929 1.29289C15.1054 1.48043 15 1.73478 15 2C15 2.26522 15.1054 2.51957 15.2929 2.70711C15.4804 2.89464 15.7348 3 16 3H17.586L12 8.586L8.707 5.293C8.51947 5.10553 8.26516 5.00021 8 5.00021C7.73484 5.00021 7.48053 5.10553 7.293 5.293L3.293 9.293C3.11084 9.4816 3.01005 9.7342 3.01233 9.9964C3.0146 10.2586 3.11977 10.5094 3.30518 10.6948C3.49059 10.8802 3.7414 10.9854 4.0036 10.9877C4.2658 10.99 4.5184 10.8892 4.707 10.707L8 7.414L11.293 10.707C11.4805 10.8945 11.7348 10.9998 12 10.9998C12.2652 10.9998 12.5195 10.8945 12.707 10.707L19 4.414V6C19 6.26522 19.1054 6.51957 19.2929 6.70711C19.4804 6.89464 19.7348 7 20 7C20.2652 7 20.5196 6.89464 20.7071 6.70711C20.8946 6.51957 21 6.26522 21 6V2C20.9998 1.80229 20.9409 1.60909 20.8309 1.4448C20.7209 1.28052 20.5647 1.15252 20.382 1.077Z"
                fill="#1C86E3"
              />
            </svg>
            <span
              class="text-[16px] font-rubik font-medium leading-[24px] tracking-[0.15px] text-[#333333]"
              >Meu desempenho</span
            >
          </div>
          <v-icon color="blue">mdi-chevron-right</v-icon>
        </div>
      </div>
      <div class="rounded-xl bg-[#fff] p-5">
        <div class="flex items-center space-x-3 mb-5">
          <ExamsFinishedIcon />
          <span class="text-B&W-20 font-medium !text-[1rem] leading-6 tracking-[0.15px]"
            >Simulados concluídos</span
          >
          <v-chip color="blue" rounded size="small" variant="flat"
            >{{ userApplicationsFinished.length }}
          </v-chip>
        </div>
        <template v-if="isUserApplicationsLoading">
          <ApplicationSkeletonLoader />
        </template>
        <template v-else-if="paginatedApplicationsFinished?.length">
          <div>
            <ApplicationsList
              :applications="paginatedApplicationsFinished"
              :is_not_started="false"
            />
            <div class="flex justify-end mt-5 overflow-hidden">
              <v-pagination
                v-if="!isUserApplicationsLoading && totalPages > 1"
                :length="totalPages!"
                :modelValue="currentPage"
                :total-visible="mobile ? 1 : 4"
                color="primary"
                variant="outlined"
                @update:modelValue="changePage"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex justify-center items-center flex-col">
            <div class="font-bold">Não há simulados concluídos.</div>
          </div>
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import ApplicationsList from './ApplicationsList.vue'
import ExamsOpenIcon from '../icons/ExamsOpenIcon.vue'
import { computed, onMounted, readonly, ref, watch } from 'vue'
import {
  getApplicationsFromDay,
  getUserApplications,
  getUserCoursesOnActiveOrganization,
  saveFormDataIntoUserApplication,
  startUserApplication
} from '@/services/application.service'
import type { Application } from '@/models/application.model'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import moment from 'moment'
import { useLoadingStore } from '@/stores/loading'
import ApplicationSkeletonLoader from '@/components/dashboard/ApplicationSkeletonLoader.vue'
import { getActiveOrganizationData } from '@/services/organization.service'
import { getUserData } from '@/services/user.service'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { useCollection } from 'vuefire'
import { db } from '@/config/firebase'
import { ApplicationStatus } from '@/enums/application-status.enum'
import ExamsFinishedIcon from '@/components/icons/ExamsFinishedIcon.vue'

const { mobile } = useDisplay()
const loadingStore = useLoadingStore()

const applicationsNotStarted = ref([] as Application[])
const applicationsFromDay = ref([] as Application[])
const isLoadingOpenApplications = ref(false)
const isLoadingApplicationsFromDay = ref(false)
const userData = ref({} as any)
const getProviderId = ref('')
const currentPage = ref(1)
const itemsPerPage = 4

const userApplicationsFinishedSource = computed(() => {
  if (!userData.value?.id) return

  return query(
    collection(db, 'users', userData.value.id, 'applications'),
    where('status', 'in', [
      ApplicationStatus.ENDED,
      ApplicationStatus.CORRECTED,
      ApplicationStatus.PUBLISHED,
      ApplicationStatus.WAITING,
      ApplicationStatus.CANCELLED,
      ApplicationStatus.EXPIRED
    ]),
    orderBy('ended_at', 'desc')
  )
})

const userApplicationsFinished = useCollection(userApplicationsFinishedSource)

const isUserApplicationsLoading = computed(() => userApplicationsFinished?.pending?.value)

const paginatedApplicationsFinished = computed(() => {
  if (!userApplicationsFinished.value?.length) return []
  const startIndex = (currentPage.value - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  return userApplicationsFinished.value.slice(startIndex, endIndex)
})

const totalPages = computed(() => {
  return Math.ceil((userApplicationsFinished.value?.length || 0) / itemsPerPage)
})

const changePage = (page: number) => {
  currentPage.value = page
}

onMounted(async () => {
  try {
    isLoadingOpenApplications.value = true
    isLoadingApplicationsFromDay.value = true
    ;[userData.value] = await Promise.all([
      getUserData(),
      handleOpenApplications(),
      handleApplicationsFromDay(),
      getProvider()
    ])

    const queries = await getQueries()

    queries.forEach((query) => {
      onSnapshot(query, async () => {
        await Promise.all([handleOpenApplications(), handleApplicationsFromDay()])
      })
    })
  } catch (e) {
    console.log(e)
  } finally {
    isLoadingOpenApplications.value = false
    isLoadingApplicationsFromDay.value = false
  }
})

async function getProvider() {
  const organization = await getActiveOrganizationData()
  getProviderId.value = organization?.provider_id
}

async function handleOpenApplications() {
  try {
    applicationsNotStarted.value = await getUserApplications()
  } catch (e) {
    console.log(e)
  }
}

async function handleApplicationsFromDay() {
  try {
    applicationsFromDay.value = await getApplicationsFromDay(
      moment(selectedDate.value).format('YYYY-MM-DD')
    )
  } catch (e) {
    console.log(e)
  }
}

const selectedDate = ref(new Date())

watch(selectedDate, async (newDate) => {
  applicationsFromDay.value = await getApplicationsFromDay(moment(newDate).format('YYYY-MM-DD'))
})

const selectedApplication = ref({} as Application)
const dialog = ref(false)

const startCard = (application: Application) => {
  if (application?.is_google_forms) {
    window.open(application?.google_forms?.responderUri, '_blank')
    return
  }

  dialog.value = true
  selectedApplication.value = application
}

const router = useRouter()

const startApplication = async (application: Application) => {
  try {
    loadingStore.startLoading()
    const userApplicationId = await saveFormDataIntoUserApplication(application)
    await startUserApplication(userApplicationId)
    await router.push(`/resolution/${userApplicationId}`)
  } catch (e) {
    console.log(e)
  } finally {
    loadingStore.stopLoading()
  }
}

const resumeApplication = async (application: Application) => {
  await router.push(`/resolution/${application.id}`)
}

watch(dialog, (newDialog) => {
  if (!newDialog) {
    selectedApplication.value = {} as Application
  }
})

const svgPath = ref(
  readonly({
    student_no_exams: '/assets/svg/student_no_exams.svg',
    student_no_exams_done: '/assets/svg/student_confused.svg'
  })
)

async function getQueries() {
  const user = await getUserData()

  const { coursesId, schoolsId } = await getUserCoursesOnActiveOrganization()

  const organizationApplicationRef = collection(
    db,
    'organizations',
    localStorage.getItem('activeOrganization') as string,
    'applications'
  )

  const organizationApplicationQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('is_apply_to_organization', '==', true)
  )

  const schoolsApplicationsQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('schools_id', 'array-contains-any', schoolsId),
    where('is_apply_to_organization', '==', false)
  )

  const coursesApplicationsQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('courses_id', 'array-contains-any', coursesId),
    where('is_apply_to_organization', '==', false)
  )

  const usersApplicationQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('users_id', 'array-contains-any', [user.id]),
    where('is_apply_to_organization', '==', false)
  )

  const userApplicationsQuery = query(
    collection(user.ref, 'applications'),
    where('organization_id', '==', localStorage.getItem('activeOrganization') as string)
  )

  return [
    organizationApplicationQuery,
    schoolsApplicationsQuery,
    coursesApplicationsQuery,
    usersApplicationQuery,
    userApplicationsQuery
  ]
}
</script>
