<template>
  <div :class="{
      'mx-10': !mobile,
      'mx-2': mobile
    }">
    <div id="mainToolbarParent">
      <div class="flex justify-between bg-[#fff] h-[5rem] rounded-b-xl px-5 py-5 z-40 mb-5" id="mainToolbar">
        <div class="flex justify-between items-center">
          <EnemSimuladoIcon />
        </div>
        <div class="flex items-center justify-center">
          <v-btn class="!border-2 text-none" color="blue" variant="outlined" @click="btnAction">
            <span :style="fontSm">Voltar</span>
          </v-btn>
        </div>
      </div>
    </div>
    <v-row no-gutters>
      <v-col class="flex flex-row gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M4 23C3.73478 23 3.48043 22.8946 3.29289 22.7071C3.10536 22.5196 3 22.2652 3 22V19C3 18.7348 3.10536 18.4804 3.29289 18.2929C3.48043 18.1054 3.73478 18 4 18C4.26522 18 4.51957 18.1054 4.70711 18.2929C4.89464 18.4804 5 18.7348 5 19V22C5 22.2652 4.89464 22.5196 4.70711 22.7071C4.51957 22.8946 4.26522 23 4 23ZM13 22V15C13 14.7348 12.8946 14.4804 12.7071 14.2929C12.5196 14.1054 12.2652 14 12 14C11.7348 14 11.4804 14.1054 11.2929 14.2929C11.1054 14.4804 11 14.7348 11 15V22C11 22.2652 11.1054 22.5196 11.2929 22.7071C11.4804 22.8946 11.7348 23 12 23C12.2652 23 12.5196 22.8946 12.7071 22.7071C12.8946 22.5196 13 22.2652 13 22ZM20 11C19.7348 11 19.4804 11.1054 19.2929 11.2929C19.1054 11.4804 19 11.7348 19 12V22C19 22.2652 19.1054 22.5196 19.2929 22.7071C19.4804 22.8946 19.7348 23 20 23C20.2652 23 20.5196 22.8946 20.7071 22.7071C20.8946 22.5196 21 22.2652 21 22V12C21 11.7348 20.8946 11.4804 20.7071 11.2929C20.5196 11.1054 20.2652 11 20 11ZM20.382 1.077C20.261 1.0263 20.1312 1.00013 20 1H16C15.7348 1 15.4804 1.10536 15.2929 1.29289C15.1054 1.48043 15 1.73478 15 2C15 2.26522 15.1054 2.51957 15.2929 2.70711C15.4804 2.89464 15.7348 3 16 3H17.586L12 8.586L8.707 5.293C8.51947 5.10553 8.26516 5.00021 8 5.00021C7.73484 5.00021 7.48053 5.10553 7.293 5.293L3.293 9.293C3.11084 9.4816 3.01005 9.7342 3.01233 9.9964C3.0146 10.2586 3.11977 10.5094 3.30518 10.6948C3.49059 10.8802 3.7414 10.9854 4.0036 10.9877C4.2658 10.99 4.5184 10.8892 4.707 10.707L8 7.414L11.293 10.707C11.4805 10.8945 11.7348 10.9998 12 10.9998C12.2652 10.9998 12.5195 10.8945 12.707 10.707L19 4.414V6C19 6.26522 19.1054 6.51957 19.2929 6.70711C19.4804 6.89464 19.7348 7 20 7C20.2652 7 20.5196 6.89464 20.7071 6.70711C20.8946 6.51957 21 6.26522 21 6V2C20.9998 1.80229 20.9409 1.60909 20.8309 1.4448C20.7209 1.28052 20.5647 1.15252 20.382 1.077Z" fill="#1C86E3"/>
        </svg>
        <span class="text-[16px] font-rubik font-medium leading-[24px] tracking-[0.15px] text-[#333333]">Meu desempenho</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-for="ka in performanceModelView.knowledgeAreas.value" :key="ka.id" class="mb-5" elevation="0">
          <v-card-text>
            <SubjectProgressBar :total="ka.total" :correct="ka.hits" :subject="ka.name" />
            <div class="text-center mt-[24px]">
              <v-btn @click="performanceModelView.generateReinforceStudyFromQuestions(ka)" variant="elevated" text="Gerar reforço" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'
import EnemSimuladoIcon from '@/components/icons/EnemSimuladoIcon.vue'
import { computed } from 'vue'
import { useFontZoomStore } from '@/stores/font'
import { useRouter } from 'vue-router'
import SubjectProgressBar from '@/components/feedback/SubjectProgressBar.vue'
import { usePerformanceView } from '@/views/performance/usePerformanceView'

const { mobile } = useDisplay()
const router = useRouter()
const fontSm = computed(() => useFontZoomStore().getFontSm)
const btnAction = () => router.go(-1)
const performanceModelView = usePerformanceView();

</script>
